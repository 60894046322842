import {Card, CardActions, CardContent, CardHeader, Stack, Theme, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {targets, useReferentialContext} from "../../../Context";
import dayjs, {Dayjs} from "dayjs";
import Proximities from "../../../components/Charts/Proximities";
import WindowContainer from "../../../components/layout/WindowContainer";
import ForwardPercentages from "../../../components/Charts/ForwardPercentage";
import DatePicker from "../../../components/selectors/Shared/DateSelect";
import TeamHistory from "../../../components/datagrids/Competitive/TeamHistory";
import DeeplolIcon from "../../../components/Icons/DeeplolIcon";
import OpggIcon from "../../../components/Icons/OpggIcon";
import {
    DurationRadialChart,
    GoldDiffRadial,
    ValueRadialChart,
    WinratesRadial
} from "../../../components/Charts/RadialCharts";
import {ArrowRight, ChevronRight} from "@mui/icons-material";
import PaginatedStack from "../../../components/Utilities/PaginatedStack";
import TabPanel, {a11yProps} from "../../../components/tabs_switch/tabs_panel";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

export default function DashboardMain(){
    const user_team = useReferentialContext().user.team;
    const farsight = useReferentialContext().farsightApi;

    const [competitive, setCompetitive] = useState(undefined as any);
    const [scrims, setScrims] = useState(undefined as any);
    const [scrimsLastWeek, setScrimsLastWeek] = useState(undefined as any);
    const [scrimsLastMonth, setScrimsLastMonth] = useState(undefined as any);
    const [scrimsLast3Month, setScrimsLast3Month] = useState(undefined as any);
    const [scrimsLast2Week, setScrimsLast2Week] = useState(undefined as any);
    const [scrimsCurrentYear, setScrimsCurrentYear] = useState(undefined as any);

    const [competitiveDone, setCompetitiveDone] = useState(false);
    const [scrimsDone, setScrimsDone] = useState(false);
    const [tab, setTab] = useState(0);

    const [competitive_date_from, set_competitive_date_from] = useState(dayjs().subtract(4, 'months'));
    const [scrims_date_from, set_scrims_date_from] = useState(dayjs().subtract(9, 'months'));

    const aggregates = [
            'kills__sum__per_minute',
            'deaths__sum__per_minute' ,
            'participant_id__avg' ,
            'dpm__avg' ,
            'gd15__sum__per_game' ,
            'gd25__sum__per_game' ,
            'wards_placed__sum__per_minute' ,
            'vision_wards_bought__sum__per_minute' ,
            'duration__avg__in_wins' ,
            'duration__avg__in_losses' ,
            'solo_kills__sum__per_game',
            'solo_deaths__sum__per_game'
        ]
    const paramObject = {
            team1:user_team,
            groupby: ['team1'],
            aggregates: [aggregates.join(',')],
            ordering: ['participant_id__avg'],
            metrics: ['winrate'],
        }

    useEffect(() => {
        farsight.getSummariesApi(targets.competitive).aggregate({
            team1:user_team,
            additional_filters : {date__year:2024},
            groupby: ['team1'],
            aggregates: [aggregates.join(',')],
            ordering: ['participant_id__avg'],
            metrics: ['winrate,games'],
            league: 'LEC',
            split:'Summer'
        }).then(r => {
            setCompetitive(r[0] ? r[0] : undefined);
            setCompetitiveDone(true);
        })
        farsight.getSummariesApi(targets.scrims).aggregate({
            team1:user_team,
            additional_filters : {date__gte:scrims_date_from.toISOString()},
            groupby: ['team1'],
            aggregates: [aggregates.join(',')],
            ordering: ['participant_id__avg'],
            metrics: ['winrate,games'],
        }).then(r => {
            setScrims(r[0] ? r[0] : undefined);
            setScrimsDone(true);
        })


    }, [scrims_date_from, competitive_date_from]);
    const reference_day = dayjs('2024-07-25');
    useEffect(() => {
        farsight.getSummariesApi(targets.scrims).aggregate({...paramObject, additional_filters : {date__gte:reference_day.subtract(1, 'week').toISOString()}
        }).then(r => {setScrimsLastWeek(r[0] ? r[0] : undefined);})

        farsight.getSummariesApi(targets.scrims).aggregate({...paramObject, additional_filters : {date__gte:reference_day.subtract(2, 'week').toISOString()}
        }).then(r => {setScrimsLast2Week(r[0] ? r[0] : undefined);})

        farsight.getSummariesApi(targets.scrims).aggregate({...paramObject, additional_filters : {date__gte:reference_day.subtract(1, 'month').toISOString()}
        }).then(r => {setScrimsLastMonth(r[0] ? r[0] : undefined);})

        farsight.getSummariesApi(targets.scrims).aggregate({...paramObject, additional_filters : {date__gte:reference_day.subtract(3, 'month').toISOString()}
        }).then(r => {setScrimsLast3Month(r[0] ? r[0] : undefined);})

        farsight.getSummariesApi(targets.scrims).aggregate({...paramObject, additional_filters : {date__year:dayjs().year()}
        }).then(r => {setScrimsCurrentYear(r[0] ? r[0] : undefined);})
        console.log('Querying')
    }, []);
    console.log(scrimsLastWeek);
    console.log(scrimsCurrentYear);
    function ColumnHeader(props:{title: string}){
        return <Stack direction={'row'} sx={{display: 'flex', align:'center', justify:'center'}}>
                <Typography variant="h5" sx={{width: '33%', display:'flex', justifyContent:'left'}}>{props.title}</Typography>
                <Typography variant="h5" sx={{width: '33%', display:'flex', justifyContent:'right'}}>{'Scrims'}</Typography>
                <Typography variant="h5" sx={{width: '33%', display:'flex', justifyContent:'right'}}>{'Competitive'}</Typography>
            </Stack>
    }
    // put card text in bold

    return <WindowContainer direction={'column'} spacing={0} sx={{alignItems:'flex-start', justifyContent:'flex-start', display:'flex'}}>
        {/*<Stack direction={'row'} spacing={1}>*/}
        {/*    <DatePicker value={competitive_date_from} onChange={set_competitive_date_from} label={"Competitive Date From"} />*/}
        {/*    <DatePicker value={scrims_date_from} onChange={set_scrims_date_from} label={"Scrims Date From"} />*/}
        {/*</Stack>*/}
        {/*<TeamHistory from={scrims_date_from.unix()} until={dayjs().add(2,'month').unix()} team={user_team} target={targets.scrims} />*/}
        {
            scrims && scrimsLastWeek && scrimsLast2Week && scrimsLastMonth && scrimsLast3Month && scrimsCurrentYear ? <PaginatedStack direction={'row'} sx={{width:'100%', alignItems:'center', justifyContent:'left', display:'flex', height:'250px'}} spacing={1}>
                <Card sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrimsLastWeek.winrate * 100).toFixed(1)}% Winrate`} sx={{height:'70px', mb:0}} subheader={'Last week'}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <WinratesRadial values={[scrimsLastWeek.winrate, scrimsLast2Week.winrate, scrimsLastMonth.winrate, scrimsLast3Month.winrate, scrimsCurrentYear.winrate]}
                        labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {`${(competitive.winrate * 100).toFixed(1)}% WR in officials (${(competitive.winrate - scrimsLastWeek.winrate)>0? '+' : ''}${((competitive.winrate - scrimsLastWeek.winrate) * 100).toFixed(1)}%)`}
                    </CardActions >
                </Card>

                <Card sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrimsLastWeek.gd15__sum__per_game)>0? '+' : ''}${(scrimsLastWeek.gd15__sum__per_game).toFixed(0)} GD@15`} sx={{height:'70px', mb:0}} subheader={'Last week'}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <GoldDiffRadial values={[scrimsLastWeek.gd15__sum__per_game, scrimsLast2Week.gd15__sum__per_game, scrimsLastMonth.gd15__sum__per_game, scrimsLast3Month.gd15__sum__per_game, scrimsCurrentYear.gd15__sum__per_game]}
                                        labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {`${competitive.gd15__sum__per_game.toFixed(0)} in officials (${(competitive.gd15__sum__per_game - scrimsLastWeek.gd15__sum__per_game)>0? '+' : ''}${((competitive.gd15__sum__per_game - scrimsLastWeek.gd15__sum__per_game)).toFixed(0)})`}
                    </CardActions >
                </Card>

                <Card sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrimsLastWeek.gd25__sum__per_game)>0? '+' : ''}${(scrimsLastWeek.gd25__sum__per_game).toFixed(1)} GD@25`} sx={{height:'70px', mb:0}} subheader={'On average last week'}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <GoldDiffRadial values={[scrimsLastWeek.gd25__sum__per_game, scrimsLast2Week.gd25__sum__per_game, scrimsLastMonth.gd25__sum__per_game, scrimsLast3Month.gd25__sum__per_game, scrimsCurrentYear.gd25__sum__per_game]}
                                        labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {`${competitive.gd25__sum__per_game.toFixed(0)} in officials (${(competitive.gd25__sum__per_game - scrimsLastWeek.gd25__sum__per_game)>0? '+' : ''}${((competitive.gd25__sum__per_game - scrimsLastWeek.gd25__sum__per_game)).toFixed(0)})`}
                    </CardActions >
                </Card>

                <Card sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrimsLastWeek.solo_kills__sum__per_game).toFixed(2)} Solokills`} sx={{height:'70px', mb:0}} subheader={'On average /game last week'}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <ValueRadialChart values={[scrimsLastWeek.solo_kills__sum__per_game, scrimsLast2Week.solo_kills__sum__per_game, scrimsLastMonth.solo_kills__sum__per_game, scrimsLast3Month.solo_kills__sum__per_game, scrimsCurrentYear.solo_kills__sum__per_game]}
                                        labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']} minValue={2} maxValue={4}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {`${competitive.solo_kills__sum__per_game.toFixed(2)} in officials (${(competitive.solo_kills__sum__per_game - scrimsLastWeek.solo_kills__sum__per_game)>0? '+' : ''}${((competitive.solo_kills__sum__per_game - scrimsLastWeek.solo_kills__sum__per_game)).toFixed(2)})`}
                    </CardActions>
                </Card>

                <Card sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrimsLastWeek.solo_deaths__sum__per_game).toFixed(2)} Solodeaths`} sx={{height:'70px', mb:0}} subheader={'On average /game last week'}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <ValueRadialChart values={[scrimsLastWeek.solo_deaths__sum__per_game, scrimsLast2Week.solo_deaths__sum__per_game, scrimsLastMonth.solo_deaths__sum__per_game, scrimsLast3Month.solo_deaths__sum__per_game, scrimsCurrentYear.solo_deaths__sum__per_game]}
                                          labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']} minValue={2} maxValue={4}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {`${competitive.solo_deaths__sum__per_game.toFixed(2)} in officials (${(competitive.solo_deaths__sum__per_game - scrimsLastWeek.solo_deaths__sum__per_game)>0? '+' : ''}${((competitive.solo_deaths__sum__per_game - scrimsLastWeek.solo_deaths__sum__per_game)).toFixed(2)})`}
                    </CardActions>
                </Card>

                <Card sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${new Date(scrimsLastWeek.duration__avg__in_wins / 1000).toISOString().substring(14, 19)}`} sx={{height:'70px', mb:0}} subheader={'Victories duration last week'}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <DurationRadialChart values={[scrimsLastWeek.duration__avg__in_wins, scrimsLast2Week.duration__avg__in_wins, scrimsLastMonth.duration__avg__in_wins, scrimsLast3Month.duration__avg__in_wins, scrimsCurrentYear.duration__avg__in_wins]}
                                          labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {`${`${new Date(competitive.duration__avg__in_wins / 1000).toISOString().substring(14, 19)}`} in officials`}
                    </CardActions>
                </Card>

                <Card sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${new Date(scrimsLastWeek.duration__avg__in_losses / 1000).toISOString().substring(14, 19)}`} sx={{height:'70px', mb:0}} subheader={'Defeats duration last week'}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <DurationRadialChart values={[scrimsLastWeek.duration__avg__in_losses, scrimsLast2Week.duration__avg__in_losses, scrimsLastMonth.duration__avg__in_losses, scrimsLast3Month.duration__avg__in_losses, scrimsCurrentYear.duration__avg__in_losses]}
                                             labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {`${`${new Date(competitive.duration__avg__in_losses / 1000).toISOString().substring(14, 19)}`} in officials`}
                    </CardActions>
                </Card>

                <Card sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrimsLastWeek.vision_wards_bought__sum__per_minute*5).toFixed(2)} Control Wards`} sx={{height:'70px', mb:0}} subheader={'On average /minute last week'}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <ValueRadialChart values={[scrimsLastWeek.vision_wards_bought__sum__per_minute*5, scrimsLast2Week.vision_wards_bought__sum__per_minute*5, scrimsLastMonth.vision_wards_bought__sum__per_minute*5, scrimsLast3Month.vision_wards_bought__sum__per_minute*5, scrimsCurrentYear.vision_wards_bought__sum__per_minute*5]}
                                          labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']} minValue={0.9} maxValue={1.1}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {`${(competitive.vision_wards_bought__sum__per_minute*5).toFixed(2)} in officials (${(competitive.vision_wards_bought__sum__per_minute - scrimsLastWeek.vision_wards_bought__sum__per_minute)>0? '+' : ''}${(((competitive.vision_wards_bought__sum__per_minute - scrimsLastWeek.vision_wards_bought__sum__per_minute))*5).toFixed(2)})`}
                    </CardActions>
                </Card>

                <Card sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrimsLastWeek.kills__sum__per_minute*5).toFixed(2)} Kills`} sx={{height:'70px', mb:0}} subheader={'On average /minute last week'}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <ValueRadialChart values={[scrimsLastWeek.kills__sum__per_minute*5, scrimsLast2Week.kills__sum__per_minute*5, scrimsLastMonth.kills__sum__per_minute*5, scrimsLast3Month.kills__sum__per_minute*5, scrimsCurrentYear.kills__sum__per_minute*5]}
                                          labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']} minValue={0.5} maxValue={1}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {`${(competitive.kills__sum__per_minute*5).toFixed(2)} in officials (${(competitive.kills__sum__per_minute - scrimsLastWeek.kills__sum__per_minute)>0? '+' : ''}${(((competitive.kills__sum__per_minute - scrimsLastWeek.kills__sum__per_minute))*5).toFixed(2)})`}
                    </CardActions>
                </Card>

                <Card sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrimsLastWeek.deaths__sum__per_minute*5).toFixed(2)} Deaths`} sx={{height:'70px', mb:0}} subheader={'On average /minute last week'}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <ValueRadialChart values={[scrimsLastWeek.deaths__sum__per_minute*5, scrimsLast2Week.deaths__sum__per_minute*5, scrimsLastMonth.deaths__sum__per_minute*5, scrimsLast3Month.deaths__sum__per_minute*5, scrimsCurrentYear.deaths__sum__per_minute*5]}
                                          labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']} minValue={0.5} maxValue={1}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {`${(competitive.deaths__sum__per_minute*5).toFixed(2)} in officials (${(competitive.deaths__sum__per_minute - scrimsLastWeek.deaths__sum__per_minute)>0? '+' : ''}${(((competitive.deaths__sum__per_minute - scrimsLastWeek.deaths__sum__per_minute))*5).toFixed(2)})`}
                    </CardActions>
                </Card>

                <Card sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrimsLastWeek.dpm__avg*5).toFixed(0)} Damages`} sx={{height:'70px', mb:0}} subheader={'On average /minute last week'}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <ValueRadialChart values={[scrimsLastWeek.dpm__avg*5, scrimsLast2Week.dpm__avg*5, scrimsLastMonth.dpm__avg*5, scrimsLast3Month.dpm__avg*5, scrimsCurrentYear.dpm__avg*5]}
                                          labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']} minValue={3000} maxValue={4000}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {`${(competitive.dpm__avg*5).toFixed(0)} in officials (${(competitive.dpm__avg - scrimsLastWeek.dpm__avg)>0? '+' : ''}${(((competitive.dpm__avg - scrimsLastWeek.dpm__avg))*5).toFixed(0)})`}
                    </CardActions>
                </Card>

                <Card sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrimsLastWeek.wards_placed__sum__per_minute*5).toFixed(2)} Wards Placed`} sx={{height:'70px', mb:0}} subheader={'On average /minute last week'}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <ValueRadialChart values={[scrimsLastWeek.wards_placed__sum__per_minute*5, scrimsLast2Week.wards_placed__sum__per_minute*5, scrimsLastMonth.wards_placed__sum__per_minute*5, scrimsLast3Month.wards_placed__sum__per_minute*5, scrimsCurrentYear.wards_placed__sum__per_minute*5]}
                                          labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']} minValue={3} maxValue={3.4}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {`${(competitive.wards_placed__sum__per_minute*5).toFixed(2)} in officials (${(competitive.wards_placed__sum__per_minute - scrimsLastWeek.wards_placed__sum__per_minute)>0? '+' : ''}${(((competitive.wards_placed__sum__per_minute - scrimsLastWeek.wards_placed__sum__per_minute))*5).toFixed(2)})`}
                    </CardActions>
                </Card>
            </PaginatedStack> : <></>
        }
        <Stack direction={'row'} sx={{height:'69%', width:'100%'}}>
                <Tabs value={tab} orientation="vertical" onChange={(event, newValue) => {setTab(newValue);}} sx={{ height: "100%", width:'250px' }}>
                        <Tab label="Gold Difference" {...a11yProps(0)} sx={{fontWeight:'bold'}}/>
                        <Tab label="Jungle Prox%" {...a11yProps(1)} sx={{fontWeight:'bold'}} />
                        <Tab label="Forward% Pre-15" {...a11yProps(2)} sx={{fontWeight:'bold'}} />
                        <Tab label="Forward% Post-15" {...a11yProps(3)}  sx={{fontWeight:'bold'}}/>
                        <Tab label="Player Stats" {...a11yProps(4)}  sx={{fontWeight:'bold'}}/>
                </Tabs>
            <Stack direction={'row'} sx={{width:'100%', height:'100%'}}>
            <TabPanel value={tab} index={2}>
                <Stack direction={'row'} sx={{width:'100%', height:'90%', alignItems:'center', justifyContent:'center', display:'flex'}}>
                <ForwardPercentages paramObjectMain={{
                    team1: user_team,
                    additional_filters: {date__gte: reference_day.subtract(1,"week").toISOString()},
                    watermark: competitive_date_from.toISOString()
                }}
                paramObjectReference={{
                    team2: user_team,
                    additional_filters: {date__gte: reference_day.subtract(1,"week").toISOString()}
                }}
                target={targets.scrims} metric={'forward_percentage_pre_15'}/>
                    <ForwardPercentages paramObjectMain={{
                        team1: user_team,
                        additional_filters: {date__gte: competitive_date_from.toISOString()},
                        watermark: competitive_date_from.toISOString()
                    }}
                    paramObjectReference={{
                        team2: user_team,
                        additional_filters: {date__gte: competitive_date_from.toISOString()}
                    }}
                    target={targets.competitive} metric={'forward_percentage_pre_15'}/>
                </Stack>
            </TabPanel>
                <TabPanel value={tab} index={3}>
                    <Stack direction={'row'} sx={{width:'100%', height:'90%', alignItems:'center', justifyContent:'center', display:'flex'}}>
                        <ForwardPercentages paramObjectMain={{
                            team1: user_team,
                            additional_filters: {date__gte: reference_day.subtract(1,"week").toISOString()},
                            watermark: competitive_date_from.toISOString()
                        }}
                        paramObjectReference={{
                            team2: user_team,
                            additional_filters: {date__gte: reference_day.subtract(1,"week").toISOString()}
                        }}
                        target={targets.scrims} metric={'forward_percentage_post_15'}/>
                        <ForwardPercentages paramObjectMain={{
                            team1: user_team,
                            additional_filters: {date__gte: competitive_date_from.toISOString()},
                            watermark: competitive_date_from.toISOString()
                        }}
                                            paramObjectReference={{
                                                team2: user_team,
                                                additional_filters: {date__gte: competitive_date_from.toISOString()}
                                            }}
                                            target={targets.competitive} metric={'forward_percentage_post_15'}/>
                    </Stack>
                </TabPanel>
            <TabPanel value={tab} index={1}>
                <Stack direction={'row'} sx={{width:'100%', height:'100%'}}>
                    <Stack direction={'column'} sx={{width:'50%', height:'100%'}}>
                        <Proximities target={targets.scrims} paramObject={{team1:user_team, role: 'JUNGLE', additional_filters : {date__gte:scrims_date_from.toISOString()}}} timer={'pre15'}/>
                        <Proximities target={targets.competitive} paramObject={{team1:user_team, role: 'JUNGLE', additional_filters : {date__gte:competitive_date_from.toISOString()}}} timer={'pre15'}/>
                    </Stack>
                    <Stack direction={'column'} sx={{width:'50%', height:'100%'}}>
                        <Proximities target={targets.scrims} paramObject={{team1:user_team, role: 'JUNGLE', additional_filters : {date__gte:scrims_date_from.toISOString()}}} timer={'post15'}/>
                        <Proximities target={targets.competitive} paramObject={{team1:user_team, role: 'JUNGLE', additional_filters : {date__gte:competitive_date_from.toISOString()}}} timer={'post15'}/>
                    </Stack>
                </Stack>
            </TabPanel>
            </Stack>
        </Stack>



        {/*{ competitiveDone && scrimsDone && competitive && scrims ?*/}
        {/*    <Stack>*/}
        {/*<PlayerMetricsComparison role={'TOP_LANE'} paramObject={paramObject} scrims_date_from={scrims_date_from} competitive_date_from={competitive_date_from}/>*/}
        {/*<PlayerMetricsComparison role={'JUNGLE'} paramObject={paramObject}  scrims_date_from={scrims_date_from} competitive_date_from={competitive_date_from}/>*/}
        {/*<PlayerMetricsComparison role={'MID_LANE'} paramObject={paramObject}  scrims_date_from={scrims_date_from} competitive_date_from={competitive_date_from}/>*/}
        {/*<PlayerMetricsComparison role={'BOT_LANE'} paramObject={paramObject}  scrims_date_from={scrims_date_from} competitive_date_from={competitive_date_from}/>*/}
        {/*<PlayerMetricsComparison role={'UTILITY'} paramObject={paramObject}  scrims_date_from={scrims_date_from} competitive_date_from={competitive_date_from}/>*/}
        {/*        </Stack>*/}
        {/*: <></>}*/}
        {/*<SectionHeader text={'Jungle Proximities Early Game (Competitive)'} vertical={false}/>*/}
        {/*<Stack direction={'row'} sx={{minHeight:'350px'}} spacing={2}>*/}
        {/*    <Stack direction={'column'} sx={{width:'50%'}} spacing={2}>*/}
        {/*        <SectionHeader text={user_team} vertical={false}/>*/}
        {/*    </Stack>*/}
        {/*    <Stack direction={'column'} sx={{width:'50%'}} spacing={2}>*/}
        {/*        <SectionHeader text={`Ennemies of ${user_team}`} vertical={false}/>*/}
        {/*        <Proximities target={targets.competitive} paramObject={{team2:user_team, role: 'JUNGLE', additional_filters : {date__gte:competitive_date_from.toISOString()}}} timer={'pre15'}/>*/}
        {/*    </Stack>*/}
        {/*</Stack>*/}

        {/*<SectionHeader text={'Jungle Proximities Early Game (Scrims)'} vertical={false}/>*/}
        {/*<Stack direction={'row'} sx={{minHeight:'350px'}} spacing={2}>*/}
        {/*    <Stack direction={'column'} sx={{width:'50%'}} spacing={2}>*/}
        {/*        <SectionHeader text={user_team} vertical={false}/>*/}
        {/*        <Proximities target={targets.scrims} paramObject={{team1:user_team, role: 'JUNGLE', additional_filters : {date__gte:scrims_date_from.toISOString()}}} timer={'pre15'}/>*/}
        {/*    </Stack>*/}
        {/*    <Stack direction={'column'} sx={{width:'50%'}} spacing={2}>*/}
        {/*        <SectionHeader text={`Ennemies of ${user_team}`} vertical={false}/>*/}
        {/*        <Proximities target={targets.scrims} paramObject={{team2:user_team, role: 'JUNGLE', additional_filters : {date__gte:scrims_date_from.toISOString()}}} timer={'pre15'}/>*/}
        {/*    </Stack>*/}
        {/*</Stack>*/}

        {/*<SectionHeader text={'Forward Percentages'} vertical={false}/>*/}
        {/*<Stack direction={'row'} sx={{minHeight:'650px', height:'650px', width:'100%'}} spacing={2}>*/}
        {/*    <ForwardPercentages  paramObjectMain={{team1:user_team, additional_filters : {date__gte:competitive_date_from.toISOString()}, watermark: 'undefined'}}*/}
        {/*                         paramObjectReference={{team2:user_team, additional_filters : {date__gte:competitive_date_from.toISOString()}}}*/}
        {/*                         target={targets.competitive} metric={'forward_percentage_pre_15'}/>*/}
        {/*    <ForwardPercentages  paramObjectMain={{team1:user_team, additional_filters : {date__gte:competitive_date_from.toISOString()}, watermark: 'undefined'}}*/}
        {/*                         paramObjectReference={{team2:user_team, additional_filters : {date__gte:competitive_date_from.toISOString()}}}*/}
        {/*                         target={targets.competitive} metric={'forward_percentage_post_15'}/>*/}
        {/*</Stack>*/}

        {/*<SectionHeader text={'Forward Percentages (Scrims)'} vertical={false}/>*/}
        {/*<Stack direction={'row'} sx={{minHeight:'650px', height:'650px', width:'100%'}} spacing={2}>*/}
        {/*    <ForwardPercentages  paramObjectMain={{team1:user_team, additional_filters : {date__gte:scrims_date_from.toISOString()}, watermark: 'undefined'}}*/}
        {/*                         paramObjectReference={{team2:user_team, additional_filters : {date__gte:scrims_date_from.toISOString()}}}*/}
        {/*                         target={targets.scrims} metric={'forward_percentage_pre_15'}/>*/}
        {/*    <ForwardPercentages  paramObjectMain={{team1:user_team, additional_filters : {date__gte:scrims_date_from.toISOString()}, watermark: 'undefined'}}*/}
        {/*                         paramObjectReference={{team2:user_team, additional_filters : {date__gte:scrims_date_from.toISOString()}}}*/}
        {/*                         target={targets.scrims} metric={'forward_percentage_post_15'}/>*/}
        {/*</Stack>*/}
    </WindowContainer>

}

const sectionHeaderStyle = (theme: Theme) => ({
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: theme.typography.h5.fontSize,
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    "& svg": {
        marginRight: theme.spacing(1),
    },
    justifyContent: "center",
    boderRadius: 2
});

function SectionHeader(props: { text: string, vertical: boolean }) {
    return (
        <Box component={"fieldset"} sx={{ ...sectionHeaderStyle, ...props.vertical ? {textOrientation: 'sideways', writingMode: 'vertical-lr'} : {}}} >
            <Typography variant="h5" align={"center"} aria-orientation={'vertical'}>
                {props.text}
            </Typography>
        </Box>
    );
}

function StatComparison(props: {scrims: number, competitive: number|undefined, format: any, stat: string}){
    return <Stack direction={'row'} sx={{display:'flex'}}>
        <Typography sx={{width: '33%', display:'flex', justifyContent:'left'}}>{props.stat}</Typography>
        <Typography sx={{width: '33%', display:'flex', justifyContent:'right'}}>{props.format(props.scrims)}</Typography>
        <Typography sx={{width: '33%', display:'flex', justifyContent:'right'}}>{props.competitive !== undefined ? props.format(props.competitive) : undefined}</Typography>
    </Stack>

}

function PlayerMetricsComparison(props: {paramObject: any, role: string, scrims_date_from: Dayjs, competitive_date_from: Dayjs}){
    const user_team = useReferentialContext().user.team;
    const farsight = useReferentialContext().farsightApi;

    const [competitive, setCompetitive] = useState({} as any);
    const [scrims, setScrims] = useState({} as any);

    const [competitiveDone, setCompetitiveDone] = useState(false);
    const [scrimsDone, setScrimsDone] = useState(false);

    useEffect(() => {
        const aggregates = [
            'kills__sum__per_minute',
            'deaths__sum__per_minute' ,
            'participant_id__avg' ,
            'dpm__avg' ,
            'gd15__sum__per_game' ,
            'gd25__sum__per_game' ,
            'wards_placed__sum__per_minute' ,
            'vision_wards_bought__sum__per_minute' ,
            'duration__avg__in_wins' ,
            'duration__avg__in_losses' ,
            'solo_kills__sum__per_game',
            'solo_deaths__sum__per_game'
        ]
        farsight.getSummariesApi(targets.competitive).aggregate({
            ...props.paramObject,
            role: props.role,
            additional_filters: {...props.paramObject.additional_filters, date__gte:props.competitive_date_from.toISOString()}
        }).then(r => {
            setCompetitive(r[0] ? r[0] : undefined);
            if(r[0]) setCompetitiveDone(true);
        })
        farsight.getSummariesApi(targets.scrims).aggregate({
            ...props.paramObject,
            role: props.role,
            additional_filters: {...props.paramObject.additional_filters, date__gte:props.scrims_date_from.toISOString()}
        }).then(r => {
            setScrims(r[0] ? r[0] : undefined);
            if(r[0]) setScrimsDone(true);
        })
    }, [props.scrims_date_from, props.competitive_date_from])

    return <Stack direction={'column'}><SectionHeader text={`${user_team} ${props.role}`} vertical={false}/>
        {competitiveDone && scrimsDone ? <Stack direction={'column'} sx={{width: '100%'}}>
            <Stack direction={'row'} sx={{display: 'flex'}}>
                <Typography sx={{width: '33%'}}>{'Stat'}</Typography>
                <Typography sx={{width: '33%'}}>{'Scrims'}</Typography>
                <Typography sx={{width: '33%'}}>{'Competitive'}</Typography>
            </Stack>
            <StatComparison stat={'Winrate'} scrims={scrims.winrate} competitive={competitive.winrate}
                            format={(r: number) => (r * 100).toFixed(2) + '%'}></StatComparison>
            <StatComparison stat={'Kills per minute'} scrims={scrims.kills__sum__per_minute}
                            competitive={competitive.kills__sum__per_minute}
                            format={(r: number) => (r).toFixed(4)}></StatComparison>
            <StatComparison stat={'Deaths per minute'} scrims={scrims.deaths__sum__per_minute}
                            competitive={competitive.deaths__sum__per_minute}
                            format={(r: number) => (r).toFixed(4)}></StatComparison>
            <StatComparison stat={'Damage per minute'} scrims={scrims.dpm__avg}
                            competitive={competitive.dpm__avg}
                            format={(r: number) => (r).toFixed(0)}></StatComparison>
            <StatComparison stat={'Wards per minute'} scrims={scrims.wards_placed__sum__per_minute}
                            competitive={competitive.wards_placed__sum__per_minute}
                            format={(r: number) => (r).toFixed(1)}></StatComparison>
            <StatComparison stat={'Control wards bought per minute'} scrims={scrims.vision_wards_bought__sum__per_minute}
                            competitive={competitive.vision_wards_bought__sum__per_minute}
                            format={(r: number) => (r).toFixed(1)}></StatComparison>
            <StatComparison stat={'GD@15'} scrims={scrims.gd15__sum__per_game}
                            competitive={competitive.gd15__sum__per_game}
                            format={(r: number) => r.toFixed(0)}></StatComparison>
            <StatComparison stat={'GD@25'} scrims={scrims.gd25__sum__per_game}
                            competitive={competitive.gd25__sum__per_game}
                            format={(r: number) => r.toFixed(0)}></StatComparison>
            <StatComparison stat={'Solo Kills'} scrims={scrims.solo_kills__sum__per_game}
                            competitive={competitive.solo_kills__sum__per_game}
                            format={(r: number) => r.toFixed(2)}></StatComparison>
            <StatComparison stat={'Solo Deaths'} scrims={scrims.solo_deaths__sum__per_game}
                            competitive={competitive.solo_deaths__sum__per_game}
                            format={(r: number) => r.toFixed(2)}></StatComparison>
            <StatComparison stat={'Durée des victoires'} scrims={scrims.duration__avg__in_wins}
                            competitive={competitive.duration__avg__in_wins}
                            format={(r: number) => new Date(r / 1000).toISOString().substring(14, 19)}></StatComparison>
            <StatComparison stat={'Durée des défaites'} scrims={scrims.duration__avg__in_losses}
                            competitive={competitive.duration__avg__in_losses}
                            format={(r: number) => new Date(r / 1000).toISOString().substring(14, 19)}></StatComparison>
        </Stack> : <></>}</Stack>
}